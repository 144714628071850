import './home.css'
import { useContext, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";

import TitleImage from '../../../components/titleImage/TitleImage.jsx'
import { Link } from 'react-router-dom'
import AutoLogout from '../../../components/autoLogout/AutoLogout';
import UpcomingReservations from '../../../components/upcomingReservations/UpcomingReservations';
import DisplayTime from '../../../components/displayTime/DisplayTime.jsx';

const Home = () => {
    const { user } = useContext(AuthContext)
    return (
        <AutoLogout>
            <div className="assocHomeContainer">
                <TitleImage />
                <div className="assocHomeBody">
                    <div className="assocHomeTopSection">
                        <div className="assocHomeTopSectionLowerRow">
                            <div className="assocHomeGreetings">
                                Good day, { user ? `${user.firstName}!` : ''}
                            </div>
                            <div className="assocHomeTimeNow">
                                <DisplayTime />
                            </div>
                        </div>
                    </div>
                    <div className="assocHomeLowerSection">
                        <div className="assocTiles assocUpcoming">
                            <UpcomingReservations />
                        </div>
                        <div className="assocTiles assocTasks">
                            <h3>Quick Links</h3>
                            <Link to='/reservations' className='assocLinks'>
                                <button className="assocHomeBtn">My Reservations</button>
                            </Link>
                            <Link to='/search' className='assocLinks'>
                                <button className="assocHomeBtn">Search Spaces</button>
                            </Link>
                        </div>
                        {/* <div className="assocTiles upcoming">
                            <h3>Upcoming Booking</h3>
                            <div>No upcoming booking.</div>
                        </div> */}
                    </div>
                    
                    
                </div>
            </div>
        </AutoLogout>
    )
}

export default Home
