import { format } from "date-fns";
import { useState } from "react";

const DisplayTime = () => {
    const dateTimeNow = new Date()
    const dateTimeNow_PHT = new Date(dateTimeNow).toLocaleString("en-US", {timeZone: "Asia/Manila"})
    const [displayTime, setDisplayTime] = useState(dateTimeNow_PHT)
    setInterval(() => {
        setDisplayTime(new Date().toLocaleString("en-US", {timeZone: "Asia/Manila"}))
    }, 1000);
  return (
    <>It's {format(new Date(displayTime), "MMMM dd, yyyy hh:mm:ss bb")} </>
  )
}

export default DisplayTime