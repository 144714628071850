export const ACTION_TYPES = {
    START: "START",
    SUCCESS: "SUCCESS",
    FAILURE: "FAILURE",
    LOGOUT: "LOGOUT",
};
export const DEFAULT_NAMES = {
    APP_NAME: "CGS Reserve",
    CARELON_SITES: "Carelon Global Solutions Sites",
    NO_SITES: "No Sites",
}
export const ROLES = {
    ADMIN: "Admin",
    FACILITY: "Facility",
    WORKFORCE: "Workforce",
    MANAGER: "Manager",
    ASSOC: "Associate"
}
export const SPACE_TYPES_CODES = {
    conference: "Conference Rooms",
    training: "Training Rooms",
    gym: "Gyms",
    sleeping: "Sleeping Quarters",
    seat: "Seats"
}
export const SPACE_TYPES_NAMES = {
    CONFERENCE: "Conference Rooms",
    TRAINING: "Training Rooms",
    GYM: "Gyms",
    SLEEPING_QUARTER: "Sleeping Quarters",
    SEAT: "Seats"
}
export const SPACE_TYPES = {
    CONFERENCE: "conference",
    TRAINING: "training",
    GYM: "gym",
    SLEEPING_QUARTERS: "sleeping",
    SEAT: "seat"
}

export const SITE_DEFAULT_ID = '63b56f2241184440f9f90694'

export const SITE_NAMES = {
    AGT: "Alliance Global Tower",
    GLAS: "GLAS Tower",
    OFT: "One Fintech",
    SMS: "SM Strata"
}

export const DURATION_VALUES = ["1", "2", "3", "4"]

export const MESSAGES = {
    ERROR_CONTACT_ADMIN: "Contact System Administrator",
    ERROR_401: "Request failed with status code 401",
    ERROR_403: "Request failed with status code 403",
    ERROR_SESSION_EXPIRED: "Session has expired. Please login again.",
    NO_ROOM_INFO: "Room information unavailable",
    ROOM_UNAVAILABLE: "Room Unavailable",
    LOADING: "Loading...",
}
export const MODAL_MESSAGE = {
    RESERVATION_LIMIT: "Reservation Limit"
}

export const reservationStatusActions = [
    { name: "cancel", value: "CANCELLED" },
    { name: "approve", value: "APPROVED" },
    { name: "reject", value: "REJECTED" },
    { name: "check in", value: "CHECKED IN" },
];

export const reservationStatus = {
    SUBMITTED: "SUBMITTED",
    APPROVED: "APPROVED",
    REJECTED: "REJECTED",
    CANCELLED: "CANCELLED",
    UPCOMING: "UPCOMING",
    CHECKEDIN: "CHECKED IN",
    NOTCHECKEDIN: "NOT CHECKED IN"
};

export const GLOBAL = {
    SESSION_DURATION: 1000 * 60 * 10, // 10 minutes
    CHECK_IN_WINDOW: 15, // minutes
}